/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "~/components/layout"
import Seo from "~/components/seo"

const Page = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo title={page.title} />
      <section className="py-20 bg-primary">
        <div className="container mx-auto px-8">
          <div className="flex -mx-8">
            <div className="w-full px-8">
            </div>
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <div className="flex justify-center -mx-8">
            <div className="w-full md:w-3/4 px-8">
              <h1
                className="text-6xl text-primary mb-8">{page.title}</h1>
              <div
                sx={{
                  ul: {
                    listStyle: 'inside',
                    pl: 4,
                    my: 6
                  }
                }}
                dangerouslySetInnerHTML={{
                  __html: page.content
                }} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query DefaultPageQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId}) {
      databaseId
      title
      content
    }
  }
`

export default Page